import KpiPanel from "./KpiPanel";
import { controls } from "..";

export class TimeKpi extends KpiPanel {
	constructor(props) {
		super(props);
	}

	onCreateMenu() {
		return [
			{
				controlId: "timeKpiMenu",
				icon: "/assets/icons/timeKPI.svg",
				title: "Time",
				children: [
					{ controlId: 0, thumbCss: {transform: "scale(0.9, 0.7)", transformOrigin: "0 0"} },
					// { controlId: 1, thumbCss: {transform: "scale(1, 0.3)", transformOrigin: "0 0"}},
					{ controlId: 1, data: <img src='./assets/thumbs/progress.png' style={{height: "295px", width: "250px"}}></img> },
					{ controlId: 3, thumbCss: { transform: "scale(1, 0.5)", transformOrigin: "0 0" } },
					{ controlId: 5, data: <img src='./assets/thumbs/gantt.png' style={{height: "295px", width: "250px"}}></img> },
					// { controlId: 5, thumbCss: {transform: "scale(1, 1)", transformOrigin: "0 0"}},
				],
			},
		];
	}
}

export default TimeKpi;
