import React from "react";
//import logo from './logo.svg';
import "./App.css";
//import Anyplan from './Anyplan';
import Login from "./Login";
import { registerLicense } from "@syncfusion/ej2-base";
import { IGlobalState } from "./types/global";
import { connect } from "react-redux";
//import Dashboard from './controls/Dashboard';
import { Dashboard } from "@controls/Dashboard";
//import { WindowContextProvider } from "./controls/WindowContext";
//import Dashboard from '@controls/Dashboard';
import { WindowContext, WindowContextProvider } from "@controls/WindowContext";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Details from "pages/ForecastStatusDetails";
import Home from "pages/Home";

// License Key for Essential Studio Enterprise Edition: 22.2.10
registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cXGFCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXZednRXQmFYVEd+Xko=');

export interface IStateProps {
	token: string;
}

type Props = IStateProps;

export class App extends React.Component<Props, any> {
	static contextType= WindowContext;
	render() {
		const { token } = this.props;

		const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};

		console.log({ Login: token });
		console.log({clientHeight, clientWidth});

		//return  token?<Dashboard /> : <Login />
		return (
			<Home />
			// <Routes>
			// 	<Route path="/" element={<Home />}></Route>
			// 	<Route path="/details" element={<Details />}></Route>
			// </Routes>
		);
	}
}

export function mapStateToProps(state: IGlobalState): IStateProps {
	return {
		token: state.authState.token,
	};
}

export default connect(mapStateToProps, null)(App);
