import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { ProjectActions } from "../../stores/project/action";
import * as utils from "../../stores/utils";
import { IGlobalState } from "../../types/global";

import "./index.css";

import "./dashboard.css";
import { RiskKpi } from "./Kpis/RiskKpi";
import { TimeKpi } from "./Kpis/TimeKpi";
import { fetchPortfolio } from "services/portfolios";
import { IEntityParams } from "types";
import RevenueKpi from "./Kpis/RevenueKpi";
import ResourceKpi from "./Kpis/ResourceKpi";
import CostKpi from "./Kpis/CostKpi";
import { ErrorBoundary } from "@controls/ErrorBound";
import { TempleHinduSharp } from "@mui/icons-material";
import { Button, ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { margin } from "@mui/system";

export const controls = [
	// { component: lazy(() => import("@controls/Projects")) },
	// { component: lazy(() => import("../Portfolios")) },
	{ component: lazy(() => import("../Charts/Scurve")) },
	{ component: lazy(() => import("../Chart")) },
	{ component: lazy(() => import("../Charts/PieChart")) },
	{ component: lazy(() => import("../ResourceUsages/DaysLate")) },
	{ component: lazy(() => import("../Gantt/GanttView")) },
	{ component: lazy(() => import("../Gantt/SyncGantt")) },
	{ component: lazy(() => import("../RiskAHP")) },
	{ component: lazy(() => import("../Charts/TimePie")) },
];

export const baselineName = 'Original';
//export const entityId = "ca6f029c-dff3-42ac-b27b-5d03476869fb";
//export const entityId = "357374db-b071-42a2-8288-e28526d120a5";
//export const entityId = "551fa5fb-b266-41c1-a838-619ffdf558cb"; // 90-days progressed
//export const entityId = "ad2e0616-6b32-4737-997a-28fd1098583e";
export const entityId = "6e7f8d41-cc4d-4f84-93ca-85061cea60e5"; // 14-days progressed

// export interface IStateProps {
//   projectDetails: IProjectDetails;
// }

// export interface IDispatchProps {
//   projectActions: ProjectActions;
// }

// type IProps = IStateProps & IDispatchProps;

export class Dashboard extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			entityParams: {} as IEntityParams,
			isFullView: false,
		};
	}
	async onGetEntity() {
		//const entityDetails = await fetchPortfolio("6e7f8d41-cc4d-4f84-93ca-85061cea60e5", "2021.04.06");
		const entityDetails = await fetchPortfolio(entityId, "2021.04.06");
		this.setState({
			entityParams: {
				Entity: entityDetails,
				EntityIdName: "portfolioId",
				Prefix: "Portfolio",
				EntityUrl: "portfolio",
			},
		});
	}

	async componentDidMount() {
		await this.onGetEntity();
	}

	onSelectedItem(component) {
		this.setState({ isFullView: true, component });
	}

	onBack() {
		this.setState({ isFullView: false });
	}

	render() {
		const { entityParams, component, isFullView } = this.state;

		if (!isFullView || !component) {
			return (
				<div style={{ margin: "5px" }}>
					<TimeKpi EntityParams={entityParams} selectedItem={this.onSelectedItem.bind(this)} />
					{/* <RiskKpi EntityParams={entityParams} selectedItem={this.onSelectedItem.bind(this)} />
					<RevenueKpi EntityParams={entityParams} selectedItem={this.onSelectedItem.bind(this)} />
					<ResourceKpi EntityParams={entityParams} selectedItem={this.onSelectedItem.bind(this)} />
					<CostKpi EntityParams={entityParams} selectedItem={this.onSelectedItem.bind(this)} /> */}
				</div>
			);
		}

		const Control = component;
		return (
			<div style={{ margin: "5px" }}>
				<div
					onClick={this.onBack.bind(this)}
					style={{
						cursor: "pointer",
						textAlign: "center",
						borderRadius: "5px",
						width: "75px",
						fontSize: "20px",
						borderStyle: "solid",
						borderColor: "black",
					}}
				>
					Back
				</div>
				<ErrorBoundary fallback={<p>Something went wrong</p>}>
					<Suspense fallback={<div>Loading...</div>}>
						{entityParams?.Entity && <Control EntityParams={entityParams} IsFullView={true}/>}
					</Suspense>
				</ErrorBoundary>
			</div>
		);
	}
}

//   const [items, setItems] = React.useState(getItems);
//   const [selected, setSelected] = React.useState([]);
//   const [position, setPosition] = React.useState(0);

//   const isItemSelected = (id) => !!selected.find((el) => el === id);

//   const handleClick =
//     (id) =>
//     ({ getItemById, scrollToItem }) => {
//       const itemSelected = isItemSelected(id);

//       setSelected((currentSelected) =>
//         itemSelected
//           ? currentSelected.filter((el) => el !== id)
//           : currentSelected.concat(id)
//       );
//     };

//   return (
//     <ScrollMenu>
//       {items.map(({ id }) => (
//         <Card
//           itemId={id} // NOTE: itemId is required for track items
//           title={id}
//           key={id}
//           onClick={handleClick(id)}
//           selected={isItemSelected(id)}
//         />
//       ))}
//     </ScrollMenu>
//   );
// }

// function Card({ onClick, selected, title, itemId }) {
//   const visibility = React.useContext(VisibilityContext);

//   return (
//     <div
//       onClick={() => onClick(visibility)}
//       style={{
//         width: '160px',
//       }}
//       tabIndex={0}
//     >
//       <div className="card">
//         <div>{title}</div>
//         <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
//         <div>selected: {JSON.stringify(!!selected)}</div>
//       </div>
//       <div
//         style={{
//           height: '200px',
//         }}
//       />
//     </div>
//   );
// }

// export default App;

// export const mapStateToProps = (state: IGlobalState): IStateProps => {
//   return {
//     projectDetails: state.projectState.projectDetails
//   };
// };

// export function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IDispatchProps {
//   return {
//     projectActions: bindActionCreators(utils.omit(ProjectActions, "Type"), dispatch)
//   };
// }

// const mapDispatchToProps = (dispatch: any) => ({
//   login: (params: any) => dispatch(loginRequest(params)),
// });

//export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default Dashboard;
