import { all } from "redux-saga/effects";
import { sagaCallbackGenerator } from "../utils";
import { ProjectActions } from "./action";

export function* projectSaga() {
  // prettier-ignore
  yield all([
    sagaCallbackGenerator(ProjectActions.Type.FETCH_PROJECTDETAILS)
  ]);
}

export default projectSaga;
