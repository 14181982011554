import ForecastSemiGauge from "@controls/ForecastSemiGauge";
import React from "react";
import { Colors, entityId } from "./Home";
import withRouter from "@controls/WrappedComponent";
import { Grid } from "@mui/material";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import { createSpinner, hideSpinner, showSpinner } from "@syncfusion/ej2-react-popups";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { WindowContext } from "@controls/WindowContext";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { VerticalAlignCenter } from "@mui/icons-material";

export const fontSize = 25;

export class ForecastStatus extends React.Component<any, any> {
	static contextType= WindowContext;
		
	constructor(props) {
		super(props);
		this.state = {			
			cutOffDate: new Date(Date.now()).toISOString(),
			forecast: null,
			actual: null,
			forecastResources: null,
			actualResources: null,
			statusData: {}
		};
	}

	async OnCalcTime(summary){
		const {statusData} = this.state;
		const { ForecastLate, ForecastEarly, ForecastStatus, ActualLate, ActualEarly, ActualStatus } = summary;
		const maxForecast = Math.round(Math.max(ForecastEarly, ForecastLate) / 10) * 10 + 10;
		const maxActual = Math.round(Math.max(ActualEarly, ActualLate) / 10) * 10 + 10;
		const maxScalar = Math.max(maxForecast, maxActual);

		const forecastStatus = Math.round(ForecastStatus);
		const forecast = { 
			Target: "Forecast",
			Path: "times",
			Start: maxScalar * -1, 
			End: maxScalar, 
			Colors: Colors.Forecast,
			Value: -forecastStatus};

		const actualStatus = Math.round(ActualStatus);
		const actual = { 
			...forecast,
			Target: "Actual",
			Colors: Colors.Actual,
			Value: -actualStatus};

		this.setState({ forecast, actual });

		statusData["Time"] = {actual, forecast, unit: "Days"};

		this.OnUpdateData(statusData);
	}

	async OnCalcCost(summary){
		const {statusData} = this.state;
		const { BCWS, EVCost, BC , EACCost} = summary;

		const actualResourceStatus = BCWS - EVCost;
		const forecastResourceStatus = BC - EACCost;

		const maxScalar = Math.round(Math.max(Math.abs(actualResourceStatus), Math.abs(forecastResourceStatus)) / 10) * 10 + 10;

		const forecastCost = {
			Target: "Forecast",
			Path: "cost",
			Start: maxScalar * -1, 
			End: maxScalar, 
			Colors: Colors.Forecast,
			Value: forecastResourceStatus.toFixed(0)};

		const actualCost = {
			...forecastCost,
			Target: "Actual",
			Colors: Colors.Actual,
			Value: actualResourceStatus.toFixed(0)
		};
		this.setState({ forecastCost, actualCost });

		statusData["Cost"] = {actual: actualCost, forecast: forecastCost, unit: "USD"};

		this.OnUpdateData(statusData);
	}

	async OnCalcResources(summary){
		const {statusData} = this.state;
		const { BQWS, EVQty, BQ, EACQty } = summary;

		const actualResourceStatus = BQWS - EVQty;
		const forecastResourceStatus = BQ - EACQty;

		const maxScalar = Math.round(Math.max(Math.abs(actualResourceStatus), Math.abs(forecastResourceStatus)) / 10) * 10 + 10;

		const forecastResources = {
			Target: "Forecast",
			Path: "resources",
			Start: maxScalar * -1, 
			End: maxScalar, 
			Colors: Colors.Forecast,
			BackgroundColor: "Purple",
			Value: forecastResourceStatus.toFixed(2)};

		const actualResources = {
			...forecastResources,
			Target: "Actual",
			Colors: Colors.Actual,
			BackgroundColor: "Brown",
			Value: actualResourceStatus.toFixed(2)
		};
		this.setState({ forecastResources, actualResources });
		
		statusData["Resource"] = {actual: actualResources, forecast: forecastResources, unit: "Hours"};

		this.OnUpdateData(statusData);
	}

	async OnCalcProduction(summary){
		const {statusData} = this.state;
		const { ActualProduction, ForecastProduction } = summary;

		const maxScalar = Math.round(Math.max(Math.abs(ActualProduction), Math.abs(ForecastProduction)) / 10) * 10 + 10;

		const forecastProduction = {
			Target: "Forecast",
			Path: "production",
			Start: maxScalar * -1, 
			End: maxScalar, 
			Colors: Colors.Forecast,
			Value: ForecastProduction.toFixed(2)};

		const actualProduction = {
			...forecastProduction,
			Target: "Actual",
			Colors: Colors.Actual,
			Value: ActualProduction.toFixed(2)
		};
		this.setState({ forecastProduction, actualProduction });

		statusData["Production"] = {actual: actualProduction, forecast: forecastProduction, unit: "BOE"};

		this.OnUpdateData(statusData);
	}

	OnUpdateData(statusData){
		this.setState({statusData});
		sessionStorage.setItem("statusData", statusData);
	}

	async OnFetchSummary() {
		const api = `${process.env.REACT_APP_ODATA_URL}/Portfolio(${entityId})/Summary?withDetails=false`;
		try {
			const response = await fetch(api);
			const result = await response.json();
			const data = result?.data;
			if (data) {
				const baselineSummaries = data.baselineSummaries as Array<any>;
				if (baselineSummaries) {
					const originalBaselineData = baselineSummaries.find((o) => o.name == "Original");
					const properties = originalBaselineData?.properties
					if (originalBaselineData) {

						this.OnCalcTime(properties)
						this.OnCalcResources(properties);
						this.OnCalcCost(properties);
						this.OnCalcProduction(properties);
					}
				}

				const {properties} = data;
				if(properties){
					this.setState({...properties});
				}
			}

			return null;
		} catch (error) {
			if (error) {
				console.error(error);
			}
		}

		return null;
	}

	navigateCallback(args){
		const { navigate } = this.props;
		const {Path, Target, Value} = args;
        // Navigate to Another Component
		const page = Path === "production" ? "details" : "domains";
        navigate(`/${page}?path=${Path}&target=${Target}&value=${Value}&from=0`);
	}

	async componentDidMount() {
		document.body.style.backgroundColor = "black";
		createSpinner({target: document.getElementById('chartContainer')});
		showSpinner(document.getElementById('chartContainer'));

		sessionStorage.removeItem("portfolioFilter");
		await this.OnFetchSummary();
	}

	render(){
		const { actual } = this.state;
		if(actual){
			hideSpinner(document.getElementById('chartContainer'));
		}

		return (
			<>
				<div id="chartContainer" className="control-section col-lg-12 spinner-target" />
				{actual && this.renderChart()}
			</>
		);
	}

	renderChart() {
		const { 
			forecast, 
			actual, 
			actualResources, 
			forecastResources, 
			actualCost, 
			forecastCost,
			actualProduction,
			forecastProduction,
			ActualTotalIndex,
			ForecastTotalIndex,
			statusData
		} = this.state;
		const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};

		const actualColor = ActualTotalIndex < 0 ? "red" : "green";
		const forecastColor = ForecastTotalIndex < 0 ? "red" : "green";

		const displayMode = clientHeight > 660 ? "flex" : "none";

		const height = (5*clientHeight-3200) / 6;
		const width = clientWidth / 2 - 100;

		return (
			<>
				<div>
					<div style={{display: displayMode, marginTop: "20px"}}>
						<div style={{width: "100%", textAlign: "center"}}>
							<img src="Insight24 Logo.png" style={{height: `${height}px`, maxHeight: "75px"}}></img>
						</div>						
					</div>	
					<div style={{position: "absolute", bottom: "50px", width: "100%"}}>
							{
								Object.keys(statusData).map((key, index) => {
									const {actual, forecast, unit} = statusData[key];
									const top = (110 + fontSize) * index + 10;
									return (
										<div key={key} style={{marginTop: `${fontSize}px`}}>											
											<div style={{display: "block", position: "absolute", width: "200px", left: `${width}px`, top: `${top}px`}}>
												<div style={{ textAlign: "center", fontWeight: "bold", color: "white", fontSize: `${fontSize}px`}}>{key}</div>
												<div style={{ textAlign: "center", fontWeight: "normal", fontStyle: "italic", color: "white"}}>{unit}</div>
											</div>							
											<div style={{display: "flex", justifyContent: "center"}}><ForecastSemiGauge {...actual} OnNavigated={this.navigateCallback.bind(this)}/><ForecastSemiGauge {...forecast} OnNavigated={this.navigateCallback.bind(this)} /></div>
										</div>
									)
								})
							}
							<p/>
							<div>
								<div style={{ textAlign: "center", fontWeight: "bold", color: "white", fontSize: `${fontSize}px`}}>Total</div>
								<div style={{display: "block", position: "absolute", width: "200px", left: `${width}px`}}>
									<div style={{ textAlign: "center", fontWeight: "normal", fontStyle: "italic", color: "white"}}>Index</div>
								</div>
								<div>
								<div style={{display: "flex", color: "white", verticalAlign: "center", justifyContent: "center"}}>
									<div style={{width: "160px"}}>
										<div style={{ textAlign: "center", fontWeight: "bolder", fontSize: `${fontSize-5}px`}}>Actual</div>
										<div style={{ textAlign: "center", fontWeight: "bold", backgroundColor: actualColor, marginTop: "5px", fontSize: `${fontSize-5}px`}}>{parseFloat(ActualTotalIndex).toFixed(2)}%</div>
									</div>
									<div style={{width: "5px"}}></div>
									<div style={{width: "160px"}}>
										<div style={{ textAlign: "center", fontWeight: "bolder", fontSize: `${fontSize-5}px` }}>Forecast</div>
										<div style={{ textAlign: "center", fontWeight: "bold", backgroundColor: forecastColor, marginTop: "5px", fontSize: `${fontSize-5}px`}}>{parseFloat(ForecastTotalIndex).toFixed(2)}%</div>
									</div>
								</div>
								</div>
							</div>
						</div>
				</div>
			</>
		);
	}
}

//export default ForecastStatus;
export default withRouter(ForecastStatus);
