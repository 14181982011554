import React from "react";
import { Route, Routes } from "react-router-dom";
import { fetchPortfolio } from "services/portfolios";
import { IEntityParams } from "types";
import ForecastStatus from "./ForecastStatus";
import ForecastStatusDetails from "./ForecastStatusDetails";
import { NoPage } from "./NoPage";
import ForecastStatusDomains from "./ForecastStatusDomains";

export const Colors = {
	Actual: { Positive: "green", Negative: "red"},
	Forecast: { Positive: "#006400", Negative: "#C30000"}
};

export const baselineName = 'Original';
//export const entityId = "ca6f029c-dff3-42ac-b27b-5d03476869fb";
//export const entityId = "357374db-b071-42a2-8288-e28526d120a5";
//export const entityId = "551fa5fb-b266-41c1-a838-619ffdf558cb"; // 90-days progressed
//export const entityId = "ad2e0616-6b32-4737-997a-28fd1098583e";
export const entityId = "6e7f8d41-cc4d-4f84-93ca-85061cea60e5"; // 14-days progressed

// export interface IStateProps {
//   projectDetails: IProjectDetails;
// }

// export interface IDispatchProps {
//   projectActions: ProjectActions;
// }

// type IProps = IStateProps & IDispatchProps;

export const TargetUnits = {times: "Days", cost: "$", resources: "Hours"};

export class Home extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			entityParams: {} as IEntityParams,
			isFullView: false,
		};
	}
	async onGetEntity() {
		//const entityDetails = await fetchPortfolio("6e7f8d41-cc4d-4f84-93ca-85061cea60e5", "2021.04.06");
		const entityDetails = await fetchPortfolio(entityId, "2021.04.06");
		this.setState({
			entityParams: {
				Entity: entityDetails,
				EntityIdName: "portfolioId",
				Prefix: "Portfolio",
				EntityUrl: "portfolio",
			},
		});
	}

	async componentDidMount() {
		await this.onGetEntity();
	}

	onSelectedItem(component) {
		this.setState({ isFullView: true, component });
	}

	onBack() {
		this.setState({ isFullView: false });
	}

	render() {
        const {entityParams} = this.state;
		return (
			<Routes>
				<Route path="/" element={<ForecastStatus EntityParams={entityParams} />} />
				<Route path="domains" element={<ForecastStatusDomains EntityParams={entityParams} />}>					
					<Route path=":typeId" element={<ForecastStatusDomains EntityParams={entityParams} />} />
				</Route>				
				<Route path="details" element={<ForecastStatusDetails EntityParams={entityParams} />}>					
					<Route path=":typeId" element={<ForecastStatusDetails EntityParams={entityParams} />} />
				</Route>
				<Route path="*" element={<NoPage />} />
			</Routes>
		);
	}
}

export default Home;
