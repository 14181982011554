import { ForecastStackBar } from "@controls/ForecastStackbar";
import { WindowContext } from "@controls/WindowContext";
import withRouter from "@controls/WrappedComponent";
import _ from "lodash";
import * as React from 'react';
import { Colors, TargetUnits, entityId } from "./Home";

export class ForecastStatusDomains extends React.Component<any, any> {
	static contextType = WindowContext;
	constructor(props) {
		super(props);
		this.state = {
			IsValid: true,
			From: 0,
			ForecastStatusData: null,
			ActualStatusData: null,
			StatusData: null,
			PagingInfo:{},
			ChartInfo:{},
			Params:{}
		}
	}

    async OnFetchWells(params){
		if(!params)
			return null;

		const api = `${process.env.REACT_APP_ODATA_URL}/Portfolio(${entityId})/Wells?withDetails=true`;
		try {
			const response = await fetch(api);
			const result = await response.json();
            const wells = result?.data as Array<any>;
            if (wells) {
				const statusData = [];

				wells.map(well => {
					const {actual, forecast} = well;
                    
					statusData.push({
						x: well.domain,
						forecast: {
							y: Math.abs(forecast),
							text: `${Math.abs(forecast).toFixed(2)}`,
							value: forecast,
							color: forecast < 0 ? Colors.Forecast.Negative : Colors.Forecast.Positive
						},
						actual: {
							y: Math.abs(actual) * -1,
							text: `${Math.abs(actual).toFixed(2)}`,
							value: actual,
							data: {
								name: well.domain,
								details: {
									budgetAtCutoff: well.budgetAtCutoff,
									actualAtCutoff: well.actualAtCutoff,
									totalBudget: well.totalBudget,
									totalActualAndForecast: well.totalActualAndForecast,
								}
							},
							color: actual < 0 ? Colors.Actual.Negative : Colors.Actual.Positive
						}
					})
				});

				const sorting = params.value < 0 ? -1 : 1;
				switch(params.target?.toLowerCase()){
					case "actual":
						statusData.sort((a, b) => {
							const aval = a.actual.value;
							const bval = b.actual.value;
				
							if(aval > bval)
								return 1 * sorting;
							else if (aval < bval)
								return -1 * sorting;
							
							return 0;					
						})				
						break;
					default:
						statusData.sort((a, b) => {
							const aval = a.forecast.value;
							const bval = b.forecast.value;
				
							if(aval > bval)
								return 1 * sorting;
							else if (aval < bval)
								return -1 * sorting;
							
							return 0;					
						})					
						break;
				}				

				return statusData;
			}
		} catch (error) {
			if (error) {
				console.error(error);
			}
		}
	
		return null;
	}

    OnFetchActivities(activities, params){
		//const statusData = [];

		// activities
		// .map(activity => {
        //     console.log(activity);
        // })

        // Object.groupBy(activities, domain => {

        // })

        const domains = _.groupBy(activities, activity => activity.activityType );
        Object.keys(domains).map(domain => {
            const activities = domains[domain];
            activities
            .map(activity => {
                const activityBaselines = activity.activityBaselines as Array<any>;
                const originalBaselineData = activityBaselines?.find(o => o.name == "Original");
    
                const calcData = null;
    
                // switch(params.path)
                //     {
                //         case "cost":
                //             calcData = this.OnCalculateCost(originalBaselineData);
                //             break;
                //         case "resources":
                //             calcData = this.OnCalculateResources(originalBaselineData);
                //             break;
                //         default:
                //             calcData = this.OnCalculateTimes(originalBaselineData);
                //             break;
                //     }
    
                // const {actual, forecast} = calcData;
    
                // if (actual == forecast && actual == 0){
                //     return;
                // }
            });
        })
    }

    // OnCalculateTimes(values){
    //     const actual = values.ActualStatus * -1;
    //     const forecast = values.ForecastStatus * -1;

    //     statusData.push({
    //         x: domain,
    //         forecast: {
    //             y: Math.abs(forecast),
    //             text: `${Math.abs(forecast).toFixed(2)}`,
    //             value: forecast,
    //             color: forecast < 0 ? Colors.Forecast.Negative : Colors.Forecast.Positive
    //         },
    //         actual: {
    //             y: Math.abs(actual) * -1,
    //             text: `${Math.abs(actual).toFixed(2)}`,
    //             value: Math.abs(actual),
    //             color: actual < 0 ? Colors.Actual.Negative : Colors.Actual.Positive
    //         },
    //     });
    // }

    // OnCalculateTimes(summary){
    //     return {actual, forecast};
    // }

    // OnCalculateResources(summary){
    //     return {actual, forecast};
    // }

    async OnFetchSummary(params){
		if(!params)
			return null;

		const api = `${process.env.REACT_APP_ODATA_URL}/Portfolio(${entityId})/Summary?withDetails=false`;
		try {
			const response = await fetch(api);
			const result = await response.json();
            const domainSummaries = result?.data?.domainSummaries as Array<any>;
            if (domainSummaries) {
                const statusData = [];

                Object.keys(domainSummaries).map(domain => {
                    const domainSummary = domainSummaries[domain];
                    const summary = domainSummary?.find(o => o.name == "Original")?.properties;
                    if (summary) {

                        let actual = null;
                        let forecast = null;

                        switch(params.path)
                            {
                                case "production":
                                    const { ActualProduction, ForecastProduction } = summary;                                    
                                    break;
                                case "cost":
                                    const { BCWS, EVCost, BC , EACCost} = summary;                            
                                    actual = BCWS - EVCost;
                                    forecast = BC - EACCost;
                                    break;
                                case "resources":
                                    const { BQWS, EVQty, BQ, EACQty } = summary;
                                    actual = BQWS - EVQty;
                                    forecast = BQ - EACQty;
                                    break;
                                default:
                                    actual = summary.ActualStatus * -1;
                                    forecast = summary.ForecastStatus * -1;
                                    break;
                            }
            

                        statusData.push({
                            x: domain,
                            forecast: {
                                y: Math.abs(forecast),
                                text: `${Math.abs(forecast).toFixed(2)}`,
                                value: forecast,
                                color: forecast < 0 ? Colors.Forecast.Negative : Colors.Forecast.Positive
                            },
                            actual: {
                                y: Math.abs(actual) * -1,
                                text: `${Math.abs(actual).toFixed(2)}`,
                                value: Math.abs(actual),
                                color: actual < 0 ? Colors.Actual.Negative : Colors.Actual.Positive
                            },
                        });
                    }
                });

                return statusData;
			}
		} catch (error) {
			if (error) {
				console.error(error);
			}
		}
	
		return null;
	}

	OnPagingClicked(args){
		// const { StatusData } = this.state;
		// this.CalculatePaging(args, StatusData);		
	}

	OnResizeCallback(){
		// const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};
		// const {isPortrait, StatusData} = this.state;
		// const calcIsPortrait = clientHeight > clientWidth;
		// if(isPortrait != calcIsPortrait){
		// 	this.setState({isPortrait: calcIsPortrait}, () => this.SliceData(StatusData));
		// }
	}
    
    OnAxisLabelClicked(args){
        const { navigate } = this.props;
		//const {Path, Target, Value} = args;
        const {value, path, target, from} = this.state.Params;
        // Navigate to Another Component
        navigate(`/details?path=${path}&target=${target}&value=${value}&from=0`);
    }

    OnCreateDialog(args){
        //
    }

    OnValidateQueries(){
		const {searchParams} = this.props;
		if(searchParams?.length > 0)
		{
			const urlSearchParams = searchParams[0];
			const path = urlSearchParams.get("path");
			const target = urlSearchParams.get("target")?? "actual";
			const value = urlSearchParams.get("value")?? true;
			const from = urlSearchParams.get("from")??0;

			if (!path) {
				this.setState({IsValid: false});
				return null;
			}

			return {path, target, value, from};
		}

		return null;
	}

    OnPointClick(args){
        const { navigate } = this.props;
        const { target, data} = args;
        const {path} = this.state.Params;
        const {ActualStatusData,  ForecastStatusData} = this.state;
        let value = ActualStatusData[data.pointIndex].value;
        if(target === "Forecast"){
            value = ForecastStatusData[data.pointIndex].value;
        }

        value = parseFloat(value).toFixed(2);

        navigate(`/details?path=${path}&target=${target}&value=${value}&from=0&domain=${data?.point.x}`);
    }

    async componentDidMount() {
        document.body.style.backgroundColor = "transparent";

		const params = this.OnValidateQueries();
		const chartInfo = {TargetUnit: TargetUnits [params.path]};

		const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};
		const calcIsPortrait = clientHeight > clientWidth;
		this.setState({IsPortrait: calcIsPortrait, ChartInfo: chartInfo});

		let statusData = null;

		switch(params.path){
			case "production":
				statusData = await this.OnFetchWells(params);
				// this.CalculatePaging(0, statusData);
				// this.SliceData(statusData);						
				break;
			default:
				statusData = await this.OnFetchSummary(params);
		}

        const ForecastStatusData = statusData.map(item => {
            return {
                ...item.forecast,
                x: item.x				
            }
        });

        const ActualStatusData = statusData.map(item => {
            return {
                ...item.actual,
                x: item.x				
            }
        });

        const pageSize = calcIsPortrait ? 10 : 5;

        if(ActualStatusData.length < pageSize){
            for(let i = ActualStatusData.length; i < pageSize; i++)	{
                ActualStatusData.unshift({x: `NaN_${i}`, y: ""});
                ForecastStatusData.unshift({x: `NaN_${i}`, y: ""});
            }
        }

        this.setState({
            ActualStatusData, 
            ForecastStatusData, 
            IsPortrait: calcIsPortrait});


		this.setState({StatusData: statusData, Params: params});
    }

    render() {
        const {IsValid, ForecastStatusData, ActualStatusData, PagingInfo, ChartInfo, Params} = this.state;
		let dialogInfo = "Activity Details";
		
		switch(Params.path){
			case "production":
				dialogInfo = "Well details";
				break;
		}

		if(!IsValid){
			this.props.navigate("/");
		}

        return (
            <ForecastStackBar 
					DialogInfo={dialogInfo}
					PagingInfo={PagingInfo}
					ForecastStatusData={ForecastStatusData} 
					ActualStatusData={ActualStatusData} 
					ChartInfo={ChartInfo}
					OnPagingCallback={this.OnPagingClicked.bind(this)}
					OnResizeCallback={this.OnResizeCallback.bind(this)}
					CreateDialogCallback={this.OnCreateDialog.bind(this)}
                    OnPointClickCallback={this.OnPointClick.bind(this)}
				/>
        );
    }
}


export default withRouter(ForecastStatusDomains);