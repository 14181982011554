import { entityId } from "@controls/Dashboard";
import { PortfolioActions } from "../../stores/portfolio/action";
import { IPortfolio, IPortfolioDetails } from "../../types/portfolio";

export const fetchPortfolio = async (portfolioId: string, cutoffDate: string): Promise<IPortfolioDetails | undefined> => {
    //const api = `${process.env.REACT_APP_ODATA_URL}/portfolio(${portfolioId})`
    const api = `${process.env.REACT_APP_ODATA_URL}/portfolio(${entityId})`
    try {
        const response = await fetch(api);
        const data = await response.json() as IPortfolioDetails;
        window.store.dispatch({
            type:PortfolioActions.Type.FETCH_PORTFOLIODETAILS,
            payload: data
        });

        return data;
    } catch (error) {
        if (error) {
            console.error(error);
        }
    }

    return null;
}

export const fetchPortfolios = async (): Promise<Array<IPortfolio> | undefined> => {
    const api = `${process.env.REACT_APP_ODATA_URL}/portfolio`
    try {
        const response = await fetch(api);
        return await response.json() as Array<IPortfolio>;
    } catch (error) {
        if (error) {
            console.error(error);
        }
    }

    return null;
}