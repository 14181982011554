import { createAction } from "redux-actions";
import { ISagaPayload } from "../../types";
import { IProject } from "../../types/project";

export namespace ProjectActions {
  export enum Type {
    FETCH_PROJECTDETAILS = "@@ProjectActions/FETCH_PROJECTDETAILS"
  }

  export const fetchProject = createAction<ISagaPayload, IProject>(Type.FETCH_PROJECTDETAILS, (data: any) => data);
}

export type ProjectActions = Omit<typeof ProjectActions, "Type">;
