import { ILoadedEventArgs } from '@syncfusion/ej2-circulargauge';
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import {
    AnnotationDirective,
    Annotations,
    AnnotationsDirective,
    AxesDirective, AxisDirective,
    CircularGaugeComponent,
    Inject,
    PointerDirective,
    PointersDirective,
    RangeDirective,
    RangesDirective
} from '@syncfusion/ej2-react-circulargauge';
import * as React from "react";
import { IEntityParams } from "types";
import withRouter from "./WrappedComponent";

export interface IProps {
	EntityParams: IEntityParams;
    Start: number,
    End: number,
    Value: number
    Colors: any,
    Path: string,
    BackgroundColor: string,
    Target: string,
    OnNavigated: (args: any) => void,
}

export class ForecastSemiGauge extends React.Component<any, any> {
    public static defaultProps = {
        Colors: {Positive: "green", Negative: "red"}
    }

	constructor(props) {
		super(props);
        this.state = {
            logText: "",
            gaugeSize:null
        }
	}

    private gauge: CircularGaugeComponent;
    private startElement: HTMLInputElement;
    private endElement: HTMLInputElement;
    private xElement: HTMLInputElement;
    private yElement: HTMLInputElement;
    private radiusElement: HTMLInputElement;
    private angleElement: CheckBoxComponent;

    public load(args: ILoadedEventArgs): void {
        //
    }

    public hideLabel(): void {
        const labelIntersect: boolean = (document.getElementById('hidelabel') as HTMLInputElement).checked;
        this.gauge.axes[0].hideIntersectingLabel = labelIntersect;
        this.gauge.refresh();
    } 

    OnClick(args){
        // const { navigate, Type } = this.props;
        // // Navigate to Another Component
        // navigate(`/${Type}`, {Type});

        const {OnNavigated, Value, Path, Target, From} = this.props
        OnNavigated?.({Path, Target, Value, From});
    }

    render() {
        const {Start, End, Value, Colors, Target, BackgroundColor} = this.props;
        const annoValue = Value;
        const annoTitle = Target;
        const posColor = Colors.Positive;
        const negColor = Colors.Negative;
        const color = Value < 0 ? negColor : posColor;

        const valueLabel = `<div style="color:white;font-size: 20px;font-weight: lighter;font-style: oblique;margin-left:-10px;">${annoValue}<span></span></div>`;
        const titleLabel = `<div style="color:white;font-size: 15px;font-weight: lighter;font-style: oblique;margin-left:-10px;">${annoTitle}<span></span></div>`;
        return (
            <>
                <div style={{height: 110, backgroundColor: "black", overflow: "hidden"}}>
                <CircularGaugeComponent                     
                    //moveToCenter={true}
                    //style={{width: "50%", height: "50%"}}
                    //style={{width: "100px", height:"450px", top: "-150px"}}
                    //style={{width: "100%", height: "100%"}}
                    style={{width: 195, height: 450, top: -140}}
                    background='transparent' 
                    load={this.load.bind(this)} 
                    onClick={this.OnClick.bind(this)}
                     ref={g => this.gauge = g}
                    >
                    <Inject services={[Annotations]} />
                    <AxesDirective>
                        <AxisDirective
                            hideIntersectingLabel={true}
                            startAngle={270}
                            endAngle={90}
                            minimum={Start} 
                            maximum={End}
                            pointers={null}
                            labelStyle={{
                                font: {
                                    size: '0px',
                                    fontWeight: 'normal',
                                    fontFamily: 'inherit'
                                },
                                format: "{value}",
                                position: 'Outside',
                                autoAngle: true
                            }}
                            lineStyle={{ width: 0 }}
                            majorTicks={{ height: 0 }} 
                            minorTicks={{ height: 0 }}>

                                <AnnotationsDirective>
                                    <AnnotationDirective 
                                        content={valueLabel}
                                        angle={180} radius='-50%' zIndex='1'>
                                    </AnnotationDirective>
                                    <AnnotationDirective 
                                        content={titleLabel}
                                        angle={180} radius='15%' zIndex='1'>
                                    </AnnotationDirective>
                                </AnnotationsDirective>                             
                            {/* <PointersDirective>
                                <PointerDirective 
                                    animation={{ enable: false }} 
                                    value={Value} 
                                    pointerWidth={5}
                                    type="Marker"
                                    color={color}
                                    position="Cross"
                                    cap={{
                                        border: { width: 3 }
                                    }} needleTail={{
                                        length: '5%'
                                    }} />
                            </PointersDirective>                                     */}
                            <RangesDirective>
                                <RangeDirective start={0} end={Value} radius='90%' color={color} startWidth={"100%"} endWidth={"100%"} />
                                <RangeDirective start={Start} end={End} radius='90%' color={"white"} startWidth={"3%"} endWidth={"3%"} />
                            </RangesDirective>                               
                        </AxisDirective>
                    </AxesDirective>
                </CircularGaugeComponent>
                </div>
                </>
        )
    }
}

export default ForecastSemiGauge;
