import BigNumber from 'bignumber.js';

export const convertGuidToInt = (id) => {
    // remove the dashes from the given uuid and convert to a hexadecimal BigNumber object
    const bn = new BigNumber(id.replace(/-/g, ''), 16);
    // return the string representation of the BigNumber object as a decimal
    return bn.toString(10);
  };

export const convertIntToGuid = (num) => {
    // convert the string representation of the decimal number to a BigNumber object
    const bn = new BigNumber(num, 10);
    // convert the BigNumber to a hexadecimal string
    const id = bn.toString(16);
    // return the string with the dashes (8-4-4-4-12)
    return `${id.substr(0, 8)}-${id.substr(8, 4)}-${id.substr(12, 4)}-${id.substr(16, 4)}-${id.substr(20)}`;
  };


const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export const generateString = (length:number) => {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}