import { IProjectState } from "../../types/global";
import { ProjectActions } from "./action";

const initialState: IProjectState = {
  projectDetails: null
};

export const projectReducer = (state = initialState, action: any) => {
  switch(action.type){
    case ProjectActions.Type.FETCH_PROJECTDETAILS:
      return {
        ...state,
        projectDetails: action.payload
      };       
    default:
      return {
        ...state,
      };    
  }
};

export default projectReducer;