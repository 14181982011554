import { createAction } from "redux-actions";
import { ISagaPayload } from "../../types";
import { IPortfolio } from "../../types/portfolio";

export namespace PortfolioActions {
  export enum Type {
    FETCH_PORTFOLIODETAILS = "@@ProjectActions/FETCH_PORTFOLIODETAILS"
  }

  export const fetchProject = createAction<ISagaPayload, IPortfolio>(Type.FETCH_PORTFOLIODETAILS, (data: any) => data);
}

export type PortfolioActions = Omit<typeof PortfolioActions, "Type">;
