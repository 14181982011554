import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";

//import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {key: 'root', storage:storageSession}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();

const middlewares = applyMiddleware(sagaMiddleware);
//const middlewares = applyMiddleware(sagaMiddleware, logger);

export const store =  createStore(persistedReducer, middlewares);
export const persistor = persistStore(store);

window.store = store;

sagaMiddleware.run(rootSaga);