import axios from "axios";
import { IAuth } from "../../stores/auth/types";

export const login = async (payload: { userId: string; password: string }) => {
    console.log({"Payload": payload});
    const { data } = await axios.post<IAuth>(
      `${process.env.REACT_APP_ODATA_URL}/Auth/Login`,
      { UserId: payload.userId, Password: payload.password },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
      }
    );
    return {token: data};
  };

  
export const signup = async (payload: { userId: string; password: string }) => {
    const { data } = await axios.post<IAuth>(
      "https://reqres.in/api/register",
      { ...payload },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  
    return data;
  };

