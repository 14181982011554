import * as React from "react";
import moment from 'moment';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, ChartTheme,
    Legend, Category, StackingBarSeries, Tooltip, ILoadedEventArgs, DataLabel, ITooltipRenderEventArgs, Highlight, ITextRenderEventArgs
} from '@syncfusion/ej2-react-charts';
import { Browser, EmitType } from '@syncfusion/ej2-base';
import { IEntityParams } from "types";
import { WindowContext } from "./WindowContext";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack } from "@mui/material";
import { highlightSearch } from "@syncfusion/ej2-react-dropdowns";
import _ from "lodash";
import { fontSize } from "pages/ForecastStatus";
import { Paging } from "./Paging";

let isResizing = null;

export const toolTipTextRender: EmitType<ITooltipRenderEventArgs> = (args: ITooltipRenderEventArgs) => {
    args.text = args.text.indexOf('-') > 0 ? args.text.replace('-', '') : args.text;
    args.text = args.text;
};

export interface IProps {
    DialogInfo: string;
	EntityParams: IEntityParams;
	ForecastStatusData: any;
    ActualStatusData: any;
    PagingInfo: any;
    OnPagingCallback: (args) => void;
    OnResizeCallback: (args) => void;
    OnPointClickCallback: (args) => void;
    CreateDialogCallback: any;
    ShowPaging: boolean;
}

export class ForecastStackBar extends React.Component<any, any> {
    chartTitle: any;
    forecastChart: any = null;
    actualChart: any = null;
    static contextType = WindowContext;
	constructor(props) {
		super(props);
        this.state = {
            clientHeight: 667,
            clientWidth: 375,
            isDialogVisible: false,
            actualLegendX: 0,
            forecastLegendX: 0, 
            targetUnit: ""
        }
	}

    componentDidMount(): void {
        const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};
        this.setState({clientHeight, clientWidth});
        window.addEventListener("resize", this.OnResize.bind(this));
    }

    OnResize(){
        clearTimeout(isResizing);
        isResizing = setTimeout(this.props.OnResizeCallback, 500);
    }

    OnAxisLabelClick (arg): void {
        const {OnAxisLabelClickCallback} = this.props;
        if(arg.axis.name === "primaryXAxis"){
            if(OnAxisLabelClickCallback){
                OnAxisLabelClickCallback(arg.text);
            }else {
                const {ForecastStatusData, ActualStatusData} = this.props;
                const activityName = arg.text;
    
                const forecast = ForecastStatusData.find(item => item.x === activityName);
                const actual = ActualStatusData.find(item => item.x === activityName);
                const data = actual?.data;
                
                this.setState({isDialogVisible: true, selectedItem: {forecast, actual, data}});
            }
        }        
    }

    renderForecast(){
        const {selectedItem} = this.state;
        const actualData = selectedItem?.actual?.data        
        if(!actualData){
            return (<></>);
        }

        const bq = parseFloat(moment.duration(actualData.bq).asHours().toFixed(2));

        return (            
            <>
                <div><span>Start Date: {actualData.startDate}</span></div>
                <div><span>Finish Date: {actualData.finishDate}</span></div>
                <div><span>Budget Cost: {actualData.bc}$</span></div>
                <div><span>Budget Quantity: {bq} Hours</span></div>
            </>
        );
    }

    handleClickOpen = () => {
        this.setState({isDialogVisible: true});
      };
    
    handleClose = () => {
        this.setState({isDialogVisible: false});
      };

    renderDialog(){
        const {CreateDialogCallback, DialogInfo} = this.props;
        const {isDialogVisible, selectedItem} = this.state;
        const actualData = selectedItem?.actual?.data
        
        if(actualData){
            return(
                <>      
                    <Dialog
                        fullWidth={true}
                        maxWidth="md"
                        open={isDialogVisible}
                        onClose={this.handleClose.bind(this)}>
                    <DialogTitle>{DialogInfo}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content'}}
                        >
                            {CreateDialogCallback?.(selectedItem)}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose.bind(this)}>Close</Button>
                    </DialogActions>
                    </Dialog>
                    </>
                );
        }

        return(<></>);
    }

    OnChartClick (args) {
        if(args.target.includes("chart_legend_text"))
        {
            this.RefreshChart();
        }
    }

    OnLegendRender(args){
        args.shape = 'None';
    }

    textRender(args){
        //console.log(args);
        if(args?.text === "NaN"){
            args.text = "";

        }else{
            args.text = args.text.indexOf('-') > 0 ? args.text.replace('-', '') : args.text;
            args.text = args.text;    
        }
    }

    axisLabelRender(args){
        args.text = args.text.includes("NaN_")? "" : args.text;
        if(args.axis.controlParent.id === this.forecastChart?.id && args.axis.propName === "primaryXAxis"){
            args.text = "";
        }
    }

    pointRender(args){
        // console.log(args.point.text);
        // args.point.text = "";
    }

    OnPointClick(args){
        const {OnPointClickCallback} = this.props;
        OnPointClickCallback?.(args);
    }

    renderChart(args){
        const {
            statusChartData, 
            chartId, 
            titleX,
            legendText, 
            height, 
            legendX,
            onSetRef,
            targetUnit,
        } = args;
        
        return (
        <ChartComponent
            border={{width: 0}}
            margin={{left: 0}}
            chartMouseClick={this.OnChartClick.bind(this)}
            ref={instance => onSetRef(instance)}                        
            id={chartId}
            key={chartId}
            style={{ textAlign: "center" }}
            axisLabelClick={this.OnAxisLabelClick.bind(this)}
            // height={`${calcClientHeight}`}
            // width={`${calcClientWidth}`}
            //width={Browser.isDevice ? '100%' : '75%'}
            axisLabelRender={this.axisLabelRender.bind(this)}
            height={`${height}`}
            primaryXAxis={{
                valueType: 'Category',
                title: titleX,
                interval: 1,
                visible: true,
                enableTrim: true,
                maximumLabelWidth: 80,
                majorGridLines: { width: 0 },
                majorTickLines: { width: 0 }
            }}
            chartArea={{ border: { width: 0 } }}
            primaryYAxis={{
                visible: true,
                labelFormat: '{value}',
                title: targetUnit,
                lineStyle: { width: 0.5 }
            }}
            pointClick={args => this.OnPointClick({target: legendText, data: args})}
            pointRender={this.pointRender}
            textRender={this.textRender}
            tooltipRender={toolTipTextRender}
            legendRender={this.OnLegendRender.bind(this)}
            legendSettings={{ 
                position: "Custom", 
                location: { x: legendX, y: -10 }, 
                enableHighlight :true, 
                textStyle: {
                    color: "black", 
                    size: `${fontSize}px`,
                    fontWeight: "bold"
                }}}
            load={this.load.bind(this)}
            title=" "
            loaded={this.onChartLoad.bind(this)}
            tooltip={{ enable: true }}>
            <Inject services={[StackingBarSeries, DataLabel, Category, Legend, Tooltip, Highlight]} />
            <SeriesCollectionDirective>
                <SeriesDirective 
                    pointColorMapping="color"
                    dataSource={statusChartData} 
                    width={2} 
                    xName='x' 
                    yName='y' 
                    name={legendText} 
                    columnWidth={0.4} 
                    type='StackingBar' 
                    marker={{ dataLabel: { 
                        labelIntersectAction: "None",
                        name: 'text',
                        visible: true, 
                        position: 'Outer', 
                        font: { fontWeight: 'bold', color: "black" }}}}>
                </SeriesDirective>
            </SeriesCollectionDirective>
        </ChartComponent>
        );
    }

    OnPagingButtonClick(args){
        this.props.OnPagingCallback?.(args.currentTarget.getAttribute("data-index"));
    }

    OnPagingClick(args){
        this.props.OnPagingCallback?.(args);
    }

    render(){
        const {ForecastStatusData, ActualStatusData, PagingInfo, ChartInfo, OnPagingCallback, ShowPaging} = this.props;
        const {clientHeight, clientWidth, selectedItem} = this.state;
        const chartHeight = clientHeight - 100;        
        const targetUnit = ChartInfo.TargetUnit;


        let actualLegendX = 0;
        const actualLegend = this.actualChart?.legendModule;
        const actualClientRect = this.actualChart?.initialClipRect;
        if(actualLegend && actualClientRect){
            const width = actualLegend.clipRect.width.baseVal.value;
            actualLegendX = actualClientRect.x + actualClientRect.width - width - 12;
            //actualLegendX = actualClientRect.x + actualClientRect.width / 2 - width / 2;
            //this.setState({actualLegendX});
        }  

        const forecastLegendX = 0;
        // const forecastLegend = this.forecastChart?.legendModule;
        // const foreCastClientRect = this.forecastChart?.initialClipRect;
        // if(foreCastClientRect && forecastLegend){
        //     const width = forecastLegend.clipRect.width.baseVal.value;
        //     forecastLegendX = foreCastClientRect.x + foreCastClientRect.width / 2 - width / 2;
        //     //this.setState({forecastLegendX});    
        // }



        return(
            <>
                <div style={{height: "20px"}}></div>
                <Grid container>
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div style={{backgroundColor: "transparent"}}>
                                {this.renderChart({
                                    onSetRef: instance => this.actualChart = instance,
                                    chartId: "actualChartDetails",
                                    isXAxisVisible: true,
                                    legendText: "Actual",
                                    titleX: "Activities",
                                    height: chartHeight,
                                    statusChartData: ActualStatusData,
                                    legendX: actualLegendX
                                })}
                        </div>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                        <div style={{backgroundColor: "transparent"}}>
                            {
                                this.renderChart({
                                    onSetRef: instance => this.forecastChart = instance,
                                    chartId: "forecastChartDetails",
                                    isXAxisVisible: false,
                                    legendText: "Forecast",
                                    titleX: "",
                                    height: chartHeight,
                                    statusChartData: ForecastStatusData,
                                    legendX: forecastLegendX
                                })}
                        </div>  
                    </Grid>
                    <Grid item xs={12}><center><b>{targetUnit}</b></center></Grid>
                </Grid>
                {ShowPaging && <Paging PagingInfo={PagingInfo} OnPagingCallback={OnPagingCallback} />}
                {selectedItem && this.renderDialog()}
            </>
        );        
    }

    public RefreshChart(){
        console.log("refresh");
        // if (!this.chart)
        //     return;

        // const {visibleSeries, initialClipRect} = this.chart;

        // // if (visibleSeries?.length !== 2)
        // //     return;

        // // const firtElementClientRect = visibleSeries[1]?.seriesElement?.children[0]?.getBoundingClientRect();
        // // if(!firtElementClientRect)
        // //     return;

        // //     const xpos = (initialClipRect.width / 2);
        // // const centerX = firtElementClientRect.left;
        // // this.chartTitle.style.left = `${centerX}px`;


        // // console.log(xpos, centerX);

        // if (initialClipRect) {
        //     const xpos = (initialClipRect.width / 2);
        //     this.chartTitle.style.left = `${xpos + initialClipRect.x - 45}px`;

        //     const legendCollections =this.chart?.legendModule?.legendCollections;
        //     if(legendCollections)
        //     {
        //         const totalItems = legendCollections.reduce((a, b) => {
        //             const value = b.visible == true ? 1 : 0;
        //             return a + value;
        //         }, 0);

        //         if(totalItems != 2) {
        //             return;
        //         }

        //         if(visibleSeries){
        //             if(visibleSeries?.length == 2){
        //                 visibleSeries.map((serie, index) => {
        //                     const {children} = serie.textElement;
        //                     if(children != null){
        //                         const childCollection = [].slice.call(children);
        //                         if(childCollection){
        //                             childCollection.map(child => {
        //                                 const htmlChild = document.getElementById(child.id) as any;    
        //                                 if(htmlChild?.x){
        //                                     const xValue = htmlChild.x.baseVal[0].value;                                                                                
        //                                     const dx = xpos + (index == 0? -30: 30);
        //                                     if(index == 0 && xValue > dx || index == 1 && xValue < dx){
        //                                         htmlChild.x.baseVal[0].value = dx;
        //                                     }    
        //                                 }
        //                             });
        //                         }
        //                     }
        //                 });
        //             }
        //         }
        //     }
        // }    
    }

    public onChartLoad(args: ILoadedEventArgs): void {
        // const chart = args.chart;
        // if (chart == null)
        //     return;

        // const htmlCollection = chart.dataLabelElements.children;
        // if(htmlCollection?.length > 1){
        //     const dataLabels = [].slice.call(htmlCollection[1].children);
        //     dataLabels.map(element => {
        //         if(element.innerHTML === "NaN"){
        //             element.innerHTML = "";
        //         }

        //         if(element.innerHTML?.includes("-")){

        //         }
        //     });
        // }
        

        // const chart: Element = document.getElementById('charts');
        // chart.setAttribute('title', '');
        // this.RefreshChart();
        // console.log(this.actualChart);
        // const { isFlipped } = this.state;
        // if(isFlipped){
        //     console.log(isFlipped);
        //     this.setState({isFlipped: false}, () => {
        //         setTimeout(() => {
        //             console.log(isFlipped);
        //             console.log(this.actualChart);
        //             // const actualClientRect = this.actualChart?.initialClipRect;
        //             // if(actualClientRect){
        //             //     const actualLegendX = actualClientRect.width  + actualClientRect.x - 80;
        //             //     this.setState({actualLegendX});    
        //             // }
            
        //             // const foreCastClientRect = this.forecastChart?.initialClipRect;
        //             // if(actualClientRect){
        //             //     const forecastLegendX = actualClientRect.x - 100;
        //             //     this.setState({forecastLegendX});    
        //             // }                
        //         }, 0);
        //     });
        // }


        // const { isFlipped } = this.state;
        // if(isFlipped){
        //     this.setState({isFlipped: false}, () => {
        //         setTimeout(() => {
        //             const actualClientRect = this.actualChart?.initialClipRect;
        //             if(actualClientRect){
        //                 const actualLegendX = actualClientRect.width  + actualClientRect.x - 80;
        //                 this.setState({actualLegendX});    
        //             }
            
        //             const foreCastClientRect = this.forecastChart?.initialClipRect;
        //             if(foreCastClientRect){
        //                 const forecastLegendX = actualClientRect.x - 100;
        //                 this.setState({forecastLegendX});    
        //             }                
        //         }, 0);
        //     });
        // }

        // const actualClientRect = this.actualChart?.initialClipRect;
        // if(actualClientRect){
        //     console.log(actualClientRect);
        //     const actualLegendX = actualClientRect.width  + actualClientRect.x - 150;
        //     this.setState({actualLegendX});    
        // }

        // const foreCastClientRect = this.forecastChart?.initialClipRect;
        // if(foreCastClientRect){
        //     const forecastLegendX = foreCastClientRect.x + 100;
        //     this.setState({forecastLegendX});    
        // }
        
        // const actualLegend = this.actualChart?.legendModule;
        // const actualClientRect = this.actualChart?.initialClipRect;
        // if(actualLegend && actualClientRect){
        //     const width = actualLegend.clipRect.width.baseVal.value;
        //     const actualLegendX = actualClientRect.x + actualClientRect.width / 2 - width / 2;
        //     this.setState({actualLegendX});
        // }  

        // const forecastLegend = this.forecastChart?.legendModule;
        // const foreCastClientRect = this.forecastChart?.initialClipRect;
        // if(foreCastClientRect && forecastLegend){
        //     const width = forecastLegend.clipRect.width.baseVal.value;
        //     const forecastLegendX = foreCastClientRect.x + foreCastClientRect.width / 2 - width / 2;
        //     this.setState({forecastLegendX});    
        // }
    }
        
    public load(args: ILoadedEventArgs): void {
        let selectedTheme: string = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i,'Contrast') as ChartTheme;
    }   
}
