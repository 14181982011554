import _ from "lodash";
import * as React from "react";

export interface IProps {
	PagingInfo: any;
	OnPagingCallback: (args) => void;
}

export class Paging extends React.Component<any, any> {
	OnPagingButtonClick(args) {
		this.props.OnPagingCallback?.(args.currentTarget.getAttribute("data-index"));
	}

	OnPagingClick(args) {
		this.props.OnPagingCallback?.(args);
	}

    CalculatePaging(args, statusData ){
		const { IsPortrait, From} = this.state;
		const length = statusData?.length
		const pageSize = IsPortrait ? 10 : 5;
		
		const maxIndex = Math.floor(length/pageSize) % 10;
		const maxInterval = Math.floor(length/pageSize/10);
		

		let pagingInfo = {};

		let from = From;
		let interval = Math.floor(from/pageSize/10);

		switch (args){			
			case "|<":
				from = 0;
				break;
			case ">|":
				from = length;
				break;
			case "<<":		
				from = from - pageSize;
				break;		
			case ">>":
				from = from + pageSize;
				break;
			default:
				from = (interval * pageSize * 10) + pageSize * parseInt(args);
		}

		if (from <= 0)
		{
			from = 0;
			pagingInfo = {...pagingInfo, "|<": true, "<<": true};
		}	

		if (from >= length){
			from = length;
		}

		interval = Math.floor(from/pageSize/10);
		if (interval >= maxInterval) {
			
			pagingInfo = {...pagingInfo, MaxIndex: maxIndex};
			if (from >= length) {
				from = ((maxInterval * pageSize * 10)+ maxIndex * pageSize);
				pagingInfo = {...pagingInfo, ">|": true, ">>": true};
			}

			if ((from + pageSize) >= length) {
				pagingInfo = {...pagingInfo, ">|": true, ">>": true};
			}
		}

		pagingInfo = {...pagingInfo, "Index": Math.ceil(from/pageSize) % 10 };

		//console.log({interval, maxInterval, from, pageSize, pagingInfo, maxIndex});

		//this.setState({From: from, PagingInfo: pagingInfo}, () => this.SliceData(statusData));
	}

	render(): React.ReactNode {
		const { PagingInfo } = this.props;
		return (
			<div style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
				<div style={{ textAlign: "center" }}>
					{["|<", "<<", ..._.range(0, 10), ">>", ">|"].map((value, index) => {
						const maxIndex = PagingInfo["MaxIndex"];
						const btnWidth = index > 1 && index < 12 ? 18 : 30;
						const disabled = PagingInfo[value] || (index - 2 > maxIndex && index < 12);
						const selectedColor = index - 2 !== PagingInfo["Index"] ? "lightGray" : "green";
						return (
							<button
								key={`btnPaging_${index}`}
								disabled={disabled}
								onClick={this.OnPagingButtonClick.bind(this)}
								data-index={value}
								style={{
									width: `${btnWidth}px`,
									height: "20px",
									border: "0",
									backgroundColor: `${selectedColor}`,
									margin: "2px",
									whiteSpace: "nowrap",
								}}
							>
								{value}
							</button>
						);
					})}
				</div>
			</div>
		);
	}
}
