import { Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function NoPage () {
    const navigate = useNavigate();

    function OnClick(){        
        navigate("/");
    }

    return (
        <>
        <div style={{top: "50%", left: "50%", position: "absolute", transform: "translate(-50%, -50%)"}} onClick={OnClick} >
            <Stack>
                <img src="assets/icons/AnyplanLogo.svg" width={200}></img>
                <div style={{textAlign: "right", fontSize: "40px", color: "red"}}>404</div>
            </Stack>
        </div>
        </>
    );
}

export default NoPage;