import { ForecastStackBar } from "@controls/ForecastStackbar";
import * as React from 'react'
import { IEntityParams } from "types";
import { Colors, TargetUnits, entityId } from "./Home";
import moment from "moment";
import { WindowContext } from "@controls/WindowContext";
import withRouter from "@controls/WrappedComponent";
import _ from "lodash";
import { Grid } from "@mui/material";

export interface IProps {
	EntityParams: IEntityParams;
	Path: string;
	Target?: string;
	Value?: number;
	From?: number;
}

export class ForecastStatusDetails extends React.Component<any, any> {
	static contextType = WindowContext;
	constructor(props) {
		super(props);
		this.state = {
			IsValid: true,
			From: 0,
			ForecastStatusData: null,
			ActualStatusData: null,
			StatusData: null,
			PagingInfo:{},
			ChartInfo:{},
			Params:{}
		}
	}

	async OnFetchActivities(activities, params){
		const statusData = [];

		activities
		.filter((activity: any) => {
			return activity.activityType == params.domain;
		})
		.map(activity => {
			const activityBaselines = activity.activityBaselines as Array<any>;
			const originalBaselineData = activityBaselines?.find(o => o.name == "Original");

			let calcData = null;

			switch(params.path)
				{
					case "cost":
						calcData = this.OnCalculateCost(originalBaselineData);
						break;
					case "resources":
						calcData = this.OnCalculateResources(originalBaselineData);
						break;
					default:
						calcData = this.OnCalculateTimes(originalBaselineData);
						break;
				}

			const {actual, forecast} = calcData;

			if (actual == forecast && actual == 0){
				return;
			}

			statusData.push({
				x: activity.description,
				forecast: {
					y: Math.abs(forecast),
					text: `${Math.abs(forecast).toFixed(2)}`,
					value: forecast,
					color: forecast < 0 ? Colors.Forecast.Negative : Colors.Forecast.Positive
				},
				actual: {
					y: Math.abs(actual) * -1,
					text: `${Math.abs(actual).toFixed(2)}`,
					value: actual,
					data: {
						name: activity.name,
						description: activity.description,
						type: activity.activityType,
						hasStarted: originalBaselineData?.actualStartDate,
						isCompledted: originalBaselineData?.actualFinishDate,
						details: originalBaselineData
					},
					color: actual < 0 ? Colors.Actual.Negative : Colors.Actual.Positive
				}
			})
		});

		const sorting = params.value < 0 ? -1 : 1;

		switch(params.target?.toLowerCase()){
			case "actual":
				statusData.sort((a, b) => {
					const aval = a.actual.value;
					const bval = b.actual.value;
		
					if(aval > bval)
						return 1 * sorting;
					else if (aval < bval)
						return -1 * sorting;
					
					return 0;					
				})				
				break;
			default:
				statusData.sort((a, b) => {
					const aval = a.forecast.value;
					const bval = b.forecast.value;
		
					if(aval > bval)
						return 1 * sorting;
					else if (aval < bval)
						return -1 * sorting;
					
					return 0;					
				})					
				break;
		}

		//this.setState({StatusData: statusData}, this.SliceData);

		return statusData;
	}

	SliceData(statusData){
		const {From} = this.state;

		const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};
		const calcIsPortrait = clientHeight > clientWidth;
		
		const pageSize = calcIsPortrait ? 10 : 5;

		const count = statusData.length - From;
		let from =  count - pageSize;
		from = from <= 0 ? 0: from;
		from = from >= statusData.length ? statusData.length: from;
		const slicedData = _.slice(statusData, from, count);

		const ForecastStatusData = slicedData.map(item => {
			return {
				...item.forecast,
				x: item.x				
			}
		});

		const ActualStatusData = slicedData.map(item => {
			return {
				...item.actual,
				x: item.x				
			}
		});

		if(ActualStatusData.length < pageSize){
			for(let i = ActualStatusData.length; i < pageSize; i++)	{
				ActualStatusData.unshift({x: `NaN_${i}`, y: ""});
				ForecastStatusData.unshift({x: `NaN_${i}`, y: ""});
			}
		}

		this.setState({
			ActualStatusData, 
			ForecastStatusData, 
			IsPortrait: calcIsPortrait});

		//return {ForecastStatusData,  ActualStatusData};
	}

	OnCalculateCost(originalBaselineData){
		const {bcws, evCost, bc, eacCost} = originalBaselineData;

		const actual = bcws - evCost;
		const forecast = bc - eacCost;

		return {actual, forecast};
	}

	OnCalculateResources(originalBaselineData){
		const {bq, eacQty, evQty, bqws} = originalBaselineData;

		const bqHours = moment.duration(bq).asHours();
		const eacQtyHours = moment.duration(eacQty).asHours();
		const evQtyHours = moment.duration(evQty).asHours();
		const bqwsHours = moment.duration(bqws).asHours();

		const forecast = bqHours - eacQtyHours;
		const actual = bqwsHours - evQtyHours;

		return {actual, forecast};
	}

	OnCalculateTimes(originalBaselineData){		
		const {actualDaysLate, forecastDaysLate} = originalBaselineData;
		const actual = parseFloat(actualDaysLate.daysLate);
		const forecast = parseFloat(forecastDaysLate.daysLate);
		return {actual, forecast};
	}

	async OnFetchSummary(params){
		if(!params)
			return null;

		const api = `${process.env.REACT_APP_ODATA_URL}/Portfolio(${entityId})/Summary?withDetails=true`;
		try {
			const response = await fetch(api);
			const result = await response.json();
            const activities = result?.activities as Array<any>;
            if (activities) {
				return this.OnFetchActivities(activities, params);
			}
		} catch (error) {
			if (error) {
				console.error(error);
			}
		}
	
		return null;
	}

	async OnFetchWells(params){
		if(!params)
			return null;

		const api = `${process.env.REACT_APP_ODATA_URL}/Portfolio(${entityId})/Wells?withDetails=true`;
		try {
			const response = await fetch(api);
			const result = await response.json();
            const wells = result?.data as Array<any>;
            if (wells) {
				const statusData = [];

				wells.map(well => {
					const {actual, forecast} = well;

					statusData.push({
						x: well.name,
						forecast: {
							y: Math.abs(forecast),
							text: `${Math.abs(forecast).toFixed(2)}`,
							value: forecast,
							color: forecast < 0 ? Colors.Forecast.Negative : Colors.Forecast.Positive
						},
						actual: {
							y: Math.abs(actual) * -1,
							text: `${Math.abs(actual).toFixed(2)}`,
							value: actual,
							data: {
								name: well.name,
								details: {
									budgetAtCutoff: well.budgetAtCutoff,
									actualAtCutoff: well.actualAtCutoff,
									totalBudget: well.totalBudget,
									totalActualAndForecast: well.totalActualAndForecast,
								}
							},
							color: actual < 0 ? Colors.Actual.Negative : Colors.Actual.Positive
						}
					})
				});

				const sorting = params.value < 0 ? -1 : 1;
				switch(params.target?.toLowerCase()){
					case "actual":
						statusData.sort((a, b) => {
							const aval = a.actual.value;
							const bval = b.actual.value;
				
							if(aval > bval)
								return 1 * sorting;
							else if (aval < bval)
								return -1 * sorting;
							
							return 0;					
						})				
						break;
					default:
						statusData.sort((a, b) => {
							const aval = a.forecast.value;
							const bval = b.forecast.value;
				
							if(aval > bval)
								return 1 * sorting;
							else if (aval < bval)
								return -1 * sorting;
							
							return 0;					
						})					
						break;
				}				

				return statusData;
			}
		} catch (error) {
			if (error) {
				console.error(error);
			}
		}
	
		return null;
	}

	OnValidateQueries(){
		const {searchParams} = this.props;
		if(searchParams?.length > 0)
		{
			const urlSearchParams = searchParams[0];
			const path = urlSearchParams.get("path");
			const target = urlSearchParams.get("target")?? "actual";
			const value = urlSearchParams.get("value")?? true;
			const from = urlSearchParams.get("from")??0;
			const domain = urlSearchParams.get("domain");

			if (!path) {
				this.setState({IsValid: false});
				return null;
			}

			return {path, target, value, from, domain};
		}

		return null;
	}

	CalculatePaging(args, statusData ){
		const { IsPortrait, From} = this.state;
		const length = statusData?.length
		const pageSize = IsPortrait ? 10 : 5;
		
		const maxIndex = Math.floor(length/pageSize) % 10;
		const maxInterval = Math.floor(length/pageSize/10);
		

		let pagingInfo = {};

		let from = From;
		let interval = Math.floor(from/pageSize/10);

		switch (args){			
			case "|<":
				from = 0;
				break;
			case ">|":
				from = length;
				break;
			case "<<":		
				from = from - pageSize;
				break;		
			case ">>":
				from = from + pageSize;
				break;
			default:
				from = (interval * pageSize * 10) + pageSize * parseInt(args);
		}

		if (from <= 0)
		{
			from = 0;
			pagingInfo = {...pagingInfo, "|<": true, "<<": true};
		}	

		if (from >= length){
			from = length;
		}

		interval = Math.floor(from/pageSize/10);
		if (interval >= maxInterval) {
			
			pagingInfo = {...pagingInfo, MaxIndex: maxIndex};
			if (from >= length) {
				from = ((maxInterval * pageSize * 10)+ maxIndex * pageSize);
				pagingInfo = {...pagingInfo, ">|": true, ">>": true};
			}

			if ((from + pageSize) >= length) {
				pagingInfo = {...pagingInfo, ">|": true, ">>": true};
			}
		}

		pagingInfo = {...pagingInfo, "Index": Math.ceil(from/pageSize) % 10 };

		//console.log({interval, maxInterval, from, pageSize, pagingInfo, maxIndex});

		this.setState({From: from, PagingInfo: pagingInfo}, () => this.SliceData(statusData));
	}



	async componentDidMount() {
		document.body.style.backgroundColor = "transparent";

		const params = this.OnValidateQueries();
		const chartInfo = {TargetUnit: TargetUnits [params.path]};

		const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};
		const calcIsPortrait = clientHeight > clientWidth;
		this.setState({IsPortrait: calcIsPortrait, ChartInfo: chartInfo});

		let statusData = null;

		switch(params.path){
			case "production":
				statusData = await this.OnFetchWells(params);
				this.CalculatePaging(0, statusData);
				this.SliceData(statusData);						
				break;
			default:
				statusData = await this.OnFetchSummary(params);
				this.CalculatePaging(0, statusData);
				this.SliceData(statusData);		
		}

		this.setState({StatusData: statusData, Params: params});
	}

	OnPagingClicked(args){
		const { StatusData } = this.state;
		this.CalculatePaging(args, StatusData);		
	}

	OnResizeCallback(){
		const {clientHeight, clientWidth} = this.context as {clientHeight, clientWidth};
		const {isPortrait, StatusData} = this.state;
		const calcIsPortrait = clientHeight > clientWidth;
		if(isPortrait != calcIsPortrait){
			this.setState({isPortrait: calcIsPortrait}, () => this.SliceData(StatusData));
		}
	}
	
	OnCreateDialogDescription(args){
		const {actual, forecast, data} = args;
		const {Params} = this.state;
		switch(Params.path){
			case "production":
				return(
					<>
						<div><span>Well name: {data?.name}</span></div>
					</>
				);				
				break;
			default:
				return(
					<>
						<div><span>Name: {data?.name}</span></div>
						<div><span>Description: {data?.description}</span></div>
						<div><span>Domain: {data?.type}</span></div>					
					</>
				);
		}
	}

	OnCreateDialog(args){
		const {actual, forecast, data} = args;
		const {Params} = this.state;
		let actualPlanned, actualCurrent, statusText = null;
		let forecastPlanned, forecastCurrent, forecastStatus = null;
		const actualStatusColor = actual.value < 0? "red" : "green";
		const forecastStatusColor = forecast.value < 0? "red" : "green";

		const details = data.details;
		const actualActualText = data.hasStarted? "Actual" :  "Forecasted";
		const forecastActualText = data.isCompledted ? "Actual" : "Forecasted";

		const timeFormat = "DD.MM.YYYY HH:mm";

		//moment(details.plannedStartDate).format(timeFormat)

		switch(Params.path)
		{
			case "times":
				statusText = { negText: "Late", posText: "Early"};
				actualPlanned = { text: "Planned Start", value: moment(details.plannedStartDate).format(timeFormat) };
				actualCurrent = { text: `${actualActualText} Start`, value:  moment(details.startDate).format(timeFormat)};				

				forecastPlanned = { text: "Planned Finish", value: moment(details.plannedFinishDate).format(timeFormat)  };
				forecastCurrent = { text: `${forecastActualText} Finish`, value: moment(details.finishDate).format(timeFormat) };
				break;
			case "resources":
				statusText = { negText: "More", posText: "Less"};
				actualPlanned = { text: "Planned Hours", value: moment.duration(details.bq).asHours() };
				actualCurrent = { text: `${actualActualText} Hours`,value: moment.duration(details.evQty).asHours()};

				forecastPlanned = { text: "Planned Hours", value: moment.duration(details.bq).asHours() };
				forecastCurrent = { text: `${forecastActualText} Hours`, value: moment.duration(details.etcQty).asHours()};
				break;
			case "cost":
				statusText = { negText: "More", posText: "Less"};
				actualPlanned = { text: "Planned Cost", value: parseFloat(details.bc).toFixed(0) };
				actualCurrent = { text: `${actualActualText} Cost`, value: parseFloat(details.evCost).toFixed(0)};

				forecastPlanned = { text: "Planned Cost", value: parseFloat(details.bcws).toFixed(0) };
				forecastCurrent = { text: "Actual Cost", value: parseFloat(details.eacCost).toFixed(0)};
				forecastStatus = { text: "More/Less"};
				break;
			case "production":
				statusText = { negText: "Less", posText: "More"};
				actualPlanned = { text: "Planned BOE", value: `${parseFloat(details.budgetAtCutoff.usageQuantity).toFixed(2)}`};
				actualCurrent = { text: `${actualActualText} BOE`, value: `${parseFloat(details.actualAtCutoff.usageQuantity).toFixed(2)}`};

				forecastPlanned = { text: "Planned BOE", value: `${parseFloat(details.totalBudget.usageQuantity).toFixed(2)}` };
				forecastCurrent = { text: "Actual BOE", value:  `${parseFloat(details.totalActualAndForecast.usageQuantity).toFixed(2)}`};
				break;
			default:
				break;
		}

		return(
			<Grid container columns={12} rowSpacing={0.3}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					{this.OnCreateDialogDescription(args)}
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				</Grid>				
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<div><b>Actual</b></div>
				</Grid>
				<Grid item  xs={12} sm={5} md={5} lg={5} xl={5}>
					<div><span>{actualPlanned.text} : {actualPlanned.value}</span></div>
                 </Grid>
				 <Grid item  xs={12} sm={5} md={5} lg={5} xl={5}>
					<div><span>{actualCurrent.text} : {actualCurrent.value}</span></div>
                 </Grid>
				 <Grid item  xs={12} sm={2} md={2} lg={2} xl={2}>
				 	<div><span style={{color: "red"}}>{statusText.negText}</span>/<span style={{color: "green"}}>{statusText.posText}</span></div>
				 	<div><span style={{color: actualStatusColor}}>{actual.text}</span></div>
                 </Grid>
				 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<div><b>Forecast</b></div>
				</Grid>
				<Grid item  xs={12} sm={5} md={5} lg={5} xl={5}>
					<div><span>{forecastPlanned.text} : {forecastPlanned.value}</span></div>
                 </Grid>
				 <Grid item  xs={12} sm={5} md={5} lg={5} xl={5}>
					<div><span>{forecastCurrent.text} : {forecastCurrent.value}</span></div>
                 </Grid>
				 <Grid item  xs={12} sm={2} md={2} lg={2} xl={2}>
				 	<div><span style={{color: "red"}}>{statusText.negText}</span>/<span style={{color: "green"}}>{statusText.posText}</span></div>
				 	<div><span style={{color: forecastStatusColor}}>{forecast.text}</span></div>
                 </Grid>				
			</Grid>
		);
	}

	render() {
		const {IsValid, ForecastStatusData, ActualStatusData, PagingInfo, ChartInfo, Params} = this.state;
		let dialogInfo = "Activity Details";
		
		switch(Params.path){
			case "production":
				dialogInfo = "Well details";
				break;
		}

		if(!IsValid){
			this.props.navigate("/");
		}

		return (
			<>
				<ForecastStackBar 
					DialogInfo={dialogInfo}
					PagingInfo={PagingInfo}
					ForecastStatusData={ForecastStatusData} 
					ActualStatusData={ActualStatusData} 
					ChartInfo={ChartInfo}
					OnPagingCallback={this.OnPagingClicked.bind(this)}
					OnResizeCallback={this.OnResizeCallback.bind(this)}
					CreateDialogCallback={this.OnCreateDialog.bind(this)}
					ShowPaging={true}
				/>
			</>
		);
	}
}

//export default ForecastStatusDetails;
export default withRouter(ForecastStatusDetails);