import { ErrorBoundary } from "@controls/ErrorBound";
import React, { Suspense } from "react";
import { IEntityParams } from "types";
import { controls } from "..";
import "./index.css";

export interface ICardProps {
	EntityParams: IEntityParams;
	CardSettings?: any;
}

export class Card extends React.Component<ICardProps, any> {
	constructor(props) {
		super(props);
	}

	renderComponent(entity, controlId) {
		if (controlId == 0 || controlId) {
			const Control = controls[controlId].component;
			return <div className='cssControlContainer'>{entity && <Control {...this.props} />}</div>;
		}

		return <div></div>;
	}

	renderInline(cardData) {
		if (cardData) {
			return <div className='cssControlContainer'>{cardData}</div>;
		}

		return <div></div>;
	}

	render(): React.ReactNode {
		const { CardSettings: CardData, EntityParams } = this.props;

		if (!CardData) {
			return <div></div>;
		}

		const { controlId, icon, title, data } = CardData;

		let {cardCss} = CardData;
		if (!cardCss && data) cardCss = {overflowY: 'hidden'};

		return (
			<div className='cssCardFrame'>
				<div className='cssCard' style={cardCss}>
					<div className='cssCardHeader'>
						{icon && <img src={icon} style={{ width: "30px", height: "30px" }} />}
						{title && <div style={{ marginLeft: "5px" }}>{title}</div>}
					</div>
					<div className='cssCardContent'>
						<ErrorBoundary fallback={<p>Something went wrong</p>}>
							<Suspense fallback={<div>Loading...</div>}>
								{data ? this.renderInline(data) : this.renderComponent(EntityParams?.Entity, controlId)}
							</Suspense>
						</ErrorBoundary>
					</div>
				</div>
			</div>
		);
	}
}

export default Card;
