import React, { Suspense } from "react";
import Card from "../Card";
import "./index.css";
import icon from "../../../assets/icons/KPIResources_30x30.png";
import { ErrorBoundary } from "@controls/ErrorBound";
import { generateString } from "libs";
import { IEntityParams } from "types";
import { controls } from "..";
//import useDrag from "libs/useDrag";

export interface IMenuItem {
	controlId: string;
	icon: string;
	title: string;
	children: any[];
}

export interface IProps {
	menuItems?: IMenuItem[];
	EntityParams: IEntityParams;
	selectedItem: any;
}

export class HMenu extends React.Component<IProps, any> {
	private elements = new Object();
	constructor(props) {
		super(props);
		this.state = {
			isDragStart: false,
			isDragging: false,
			position: 0,
		};

		this.onSelectedItem.bind(this)
	}

	shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<any>, nextContext: any): boolean {
		if (this.state.isDragStart != nextState.isDragStart) {
			return false;
		}

		if (this.state.isDragging != nextState.isDragging) {
			return false;
		}

		if (this.state.position != nextState.position) {
			return false;
		}

		return true;
	}

	onSelectedItem(args, component) {
		const { isDragging } = this.state;
		if (isDragging) {
			return;
		}

		const { selectedItem } = this.props;
		if (selectedItem) {
			selectedItem(component);
		}
	}

	onDragStart(args: React.MouseEvent, itemKey: string) {
		this.setState({ isDragStart: true, position: args.clientX });
		//console.log("target",itemKey);
		//this.elements[itemKey].scrollTo(100,0);
	}

	onDragStop(args: React.MouseEvent) {
		this.setState({ isDragging: false, isDragStart: false });
		// window.requestAnimationFrame(() => {
		// 	this.setState({ isDragging: false, isDragStart: false });
		// });
		//console.log(args.target);
	}

	//   const dragMove = (ev: React.MouseEvent, cb: (posDif: number) => void) => {
	//     const newDiff = position.current - ev.clientX;

	//     const movedEnough = Math.abs(newDiff) > 5;

	//     if (clicked && movedEnough) {
	//       setDragging(true);
	//     }

	//     if (dragging && movedEnough) {
	//       position.current = ev.clientX;
	//       cb(newDiff);
	//     }
	//   };

	onHandleDrag(args) {
		//
	}

	// const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
	// 	ev: React.MouseEvent
	//   ) =>
	// 	dragMove(ev, (posDiff) => {
	// 	  if (scrollContainer.current) {
	// 		scrollContainer.current.scrollLeft += posDiff;
	// 	  }
	// 	});

	//   const [selected, setSelected] = React.useState<string>("");
	//   const handleItemClick = (itemId: string) => () => {
	// 	if (dragging) {
	// 	  return false;
	// 	}
	// 	setSelected(selected !== itemId ? itemId : "");
	//   };

	render(): React.ReactNode {
		const { menuItems, EntityParams } = this.props;
		//const { dragStart, dragStop, dragMove, dragging } = useDrag();

		return menuItems.map((menu) => {
			const itemKey = menu.controlId ? menu.controlId : generateString(10);
			if (menu.children?.length > 0) {
				return (
					<React.Fragment key={itemKey}>
						<div
							className='cssHMenu'
							ref={el => this.elements[itemKey] = el}
							onMouseDown={args => this.onDragStart(args, itemKey)}
							onMouseUp={this.onDragStop.bind(this)}
							onMouseMove={this.onHandleDrag.bind(this)}
						>
							<div className='cssHMenuHeader'>
								<div className='cssMenuIconContainer'>
									<img className='cssMenuIcon' src={menu.icon}></img>
								</div>
								<div className='cssMenuTitle'>{menu.title}</div>
							</div>
							<div className='cssHMenuList'>
								{menu.children.map((child) => {
									const component = controls[child.controlId].component;
									if (component) {
										const childKey = generateString(10);
										return (
											<React.Fragment key={childKey}>
												<div onClick={(args) => this.onSelectedItem(args, component)}>
													<Card
														EntityParams={EntityParams}
														CardSettings={child}
													/>
												</div>
											</React.Fragment>
										);
									}

									return <div></div>;
								})}
							</div>
						</div>
					</React.Fragment>
				);
			}

			return null;
		});
	}
}

export default HMenu;
