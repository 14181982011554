import { generateString } from "libs";
import React, { lazy } from "react";
import { IEntityParams } from "types";
import HMenu from "../HMenu";

export interface IProps {
	EntityParams: IEntityParams;
	selectedItem: any;
}

export class KpiPanel extends React.Component<IProps, any> {
	constructor(props) {
		super(props);
	}

	onCreateMenu() {
		return [];
	}

	onSelectedItem(args) {
		console.log("name", this.constructor.name);
		const {selectedItem} = this.props;
		if(selectedItem){
			selectedItem(args);
		}
	}

	render(): React.ReactNode {
		return <HMenu menuItems={this.onCreateMenu()} {...this.props} selectedItem={this.onSelectedItem.bind(this)} />;
	}
}

export default KpiPanel;
