import { combineReducers } from "redux";
import { IGlobalState } from "../types/global";

import { authReducer } from "./auth/reducer";
import { projectReducer } from "./project/reducer";

export const rootReducer = combineReducers<IGlobalState>({  
  authState: authReducer,
  projectState: projectReducer
});

//export type AuthState = ReturnType<typeof rootReducer>;

export default rootReducer;