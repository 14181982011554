import { all, call, put, takeLatest } from "redux-saga/effects";
import { login, signup } from "../../services/auth";

import {
  loginFailure,
  loginSuccess,
  signupSuccess,
  signupFailure,
} from "./actions";
import { LOGIN_REQUEST, SIGNUP_REQUEST } from "./actionTypes";

function* loginSaga(action: any) {
  try {
    const response: { token: string } = yield call(login, {
      userId: action.payload.values.userId,
      password: action.payload.values.password,
    });

    yield put(
      loginSuccess({
        token: response.token,
      })
    );
    action.payload.callback(response.token);
  } catch (e: any) {
    yield put(
      loginFailure({
        error: e.message,
      })
    );
  }
}

function* signupSaga(action: any) {
  try {
    const response: { token: string } = yield call(signup, {
      userId: action.payload.values.userId,
      password: action.payload.values.password,
    });

    yield put(
      signupSuccess({
        token: response.token,
      })
    );
    action.payload.callback(response.token);
  } catch (e: any) {
    yield put(
      signupFailure({
        error: e.message,
      })
    );
  }
}

function* authSaga() {
  yield all([takeLatest(LOGIN_REQUEST, loginSaga)]);
  yield all([takeLatest(SIGNUP_REQUEST, signupSaga)]);
}

export default authSaga;