import { call, put, takeEvery } from "redux-saga/effects";

export namespace AsyncState {
    export const SUCCESS = (param: string) => `${param}_SUCCESS`;
    export const FAILED = (param: string) => `${param}_FAILED`;
    export const REQUEST = (param: string) => `${param}_REQUEST`;
  }

  type callBack = (payload: any) => Promise<any>;

  export function* sagaCallbackHandler(actionName: string, api: callBack, payload: any) {
    try {
      const data = api ? yield call(api, payload) : payload.payload;
  
      yield put({ type: AsyncState.SUCCESS(actionName), payload: { data } });
    } catch (err: any) {
      yield put({ type: AsyncState.FAILED(actionName), payload: { error: err.message } });
    }
  }
  
  export function* sagaCallbackGenerator(actionName: string, api?: callBack) {
    yield takeEvery(actionName, sagaCallbackHandler, actionName, api);
  }

export function omit<T extends object, K extends keyof T>(target: T, ...omitKeys: K[]): Omit<T, K> {
  const keys = Object.keys(target) as K[];
  const callback = (res, key) => {
    if (!omitKeys.includes(key)) {
      res[key] = target[key];
    }

    return res;
  };

  return keys.reduce(callback, {} as Omit<T, K>);
}

export { AsyncState as S };
